<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Imágenes MYT
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          🩺 Descarga tus imagenes diagnósticas 🦴
        </h2>
        <p class="mb-3">
          Selecciona quien eres, para descargar los resultados
        </p>
        <b-row class="match-height">
          <b-col
            md="6"
            lg="6"
          >
            {{ validarLogin() }}
            <resultado-medico
              :router="router"
            />
          </b-col>
          <b-col
            md="6"
            lg="6"
          >
            <resultado-paciente />
          </b-col>
        </b-row>
      </div>
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-center">
        <cargar-resultados />
      </div>
    </div>
  </div>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BRow, BCol,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import ResultadoMedico from '@/assets/componentes/home/ResultadoMedico.vue'
import CargarResultados from '../assets/componentes/home/CargarResultados.vue'
import ResultadoPaciente from '../assets/componentes/home/ResultadoPaciente.vue'

export default {
  components: {
    CargarResultados,
    ResultadoMedico,
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    ResultadoPaciente,
  },
  data() {
    return {
      router: '',
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },

    currentUser() {
      return this.$store.state.login.user
    },
  },
  methods: {
    // eslint-disable-next-line camelcase
    validarLogin() {
      // console.log(this.currentUser)
      if (this.currentUser) this.router = 'medico'
      else (this.router = 'login')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
