<template>
  <div class="w-100 text-center">
    <b-row class="match-height">
      <b-col>
        <b-card
          class="text-center cursor-pointer"
          :img-src="require('@/assets/images/rol/medico.jpg')"
          img-alt="Card image cap"
          img-top
          title="Médico MYT"
          @click="$router.push({ name: router })"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    router: String,
  },
}
</script>
