<template>
  <b-card>
    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">Si subes resultados o eres linea de frente</span>
        </li>
      </ul>
      <center>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="redireccionarLogin()"
        >
          inicia sesion
        </b-button>
      </center>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BButton,
  },
  methods: {
    redireccionarLogin() {
      this.$store.state.tipoCargar = 'Cargar'
      this.$router.push('/login')
    },
  },
}

</script>

<style>

</style>
