<template>
	<div class="w-100 text-center">
	  <b-row class="match-height">
		<b-col>
		  <b-card
			class="text-center cursor-pointer"
			:img-src="require('@/assets/images/rol/usuario.jpg')"
			img-alt="Card image cap"
			img-top
			title="Paciente"
			@click="$router.push({ name: 'Paciente' })"
		  />
		</b-col>
  
	  </b-row>
	</div>
  </template>
  
  <script>
  import {
	BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  
  export default {
	components: {
	  BCard,
	  BCardText,
	  BButton,
	  BCardBody,
	  BCardTitle,
	  BCardSubTitle,
	  BRow,
	  BCol,
	  BLink,
	  BImg,
	},
	directives: {
	  Ripple,
	},
  }
  </script>
  